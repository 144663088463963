<template>
	<div class="product-header">
		<div class="item-name"><div class="quantity">x{{ product.quantity }}</div> {{ productTranslation }}</div>
		<div class="item-data">
			<div class="item-price" v-if="product.product_price > 0 && !menusStore.business.options.hide_prices">{{ currencyStore.readableCurrencyValue(product.product_price*product.quantity) }}</div>
		</div>
	</div>
</template>

<script>
import {useCartStore, useMenusStore} from "@/new-menu/stores";
import {useCurrencyStore, useLanguageStore} from "@/shared/stores";

export default {
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		const currencyStore = useCurrencyStore();
		return {cartStore, currencyStore, languageStore, menusStore}
	},
	name: "OrderItemProduct",
	props: {
		product: {
			type: Object,
			required: true,
		},
		productTranslation: {
			type: String,
			required: true,
		}
	},
	methods: {
	}
}
</script>

<style scoped lang="scss">
.product-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 6px 0;
	border-bottom: 1px solid var(--theme-bg1-color);

	&:last-child {
		border-bottom: none;
	}

	.item-name {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		color: var(--theme-txt1-color);
		@include truncateString();

		.quantity {
			font-size: var(--font-size-s);
			line-height: var(--line-height-s);
			color: var(--business-color);
			font-weight: 700;
			margin-right: 6px;
		}

	}

	.item-data {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;

		.item-price {
			font-size: var(--font-size-s);
			line-height: var(--line-height-s);
			color: var(--theme-txt2-color);
		}
	}
}
</style>
<template>
    <div class="coupons-list">
        <div class="new-coupon-container" :class="{loading}">
            <div class="coupon-icon" v-html="getIcon('iconCoupons')"></div>
            <div class="coupon-input-container">
                <MenuTextInput :placeholder="getString('coupon_code')" ref="coupon-input" @pressed-enter="applyCoupon"  @focus="cartStore.alertSectionCoupons(false)" @changed-text="v => tempCoupon = v" />
            </div>
            <div class="coupon-icon-add" v-html="getIcon('iconPlus')" @click="applyCoupon"></div>
        </div>
		<p class="explain-coupon" v-if="cartStore.appliedCoupons.length === 0 && tempCoupon.length > 2">{{ getString('explain_coupon_button') }}</p>
		<div class="coupon-container" v-for="coupon in cartStore.appliedCoupons">
			<div class="coupon-icon" v-html="getIcon('iconCoupons')"></div>
			<div class="coupon-code">{{ coupon.code }}</div>
			<div class="coupon-value">{{ '-'+getCouponValue(coupon) }}</div>
			<div class="coupon-delete" v-html="getIcon('iconBin')" @click="removeCoupon(coupon)"></div>
		</div>
    </div>
</template>

<script>
import {useCurrencyStore, useLanguageStore, useNotificationStore, useSvgStore} from "@/shared/stores";
import MenuTextInput from "@/new-menu/components/generic/MenuTextInput.vue";
import {useCartStore, useMenusStore} from "@/new-menu/stores";
import API from "@/shared/helpers/API";
import MenuTextAreaInput from "@/new-menu/components/generic/MenuTextAreaInput.vue";

export default {
    setup() {
        let svgStore = useSvgStore();
        let languageStore = useLanguageStore();
        let notificationStore = useNotificationStore();
        let menusStore = useMenusStore();
		let cartStore = useCartStore();
		const currencyStore = useCurrencyStore();
        return {
            getIcon: svgStore.get,
            getString: languageStore.getString,
            notificationStore,
            menusStore,
			currencyStore,
			cartStore
        }
    },
    name: "CouponsList",
    components: {
		MenuTextAreaInput,
        MenuTextInput
    },
    data() {
        return {
            loading: false,
			tempCoupon: ""
        }
    },
    methods: {
		getCouponValue(coupon) {
			if (coupon.fixed_value) {
				return this.currencyStore.readableCurrencyValue(coupon.fixed_value);
			} else {
				let percentageMaxTwoDecimals = Math.floor(coupon.percentage_value * 100) / 100;
				return percentageMaxTwoDecimals + "%";
			}
		},
        sanitizeCouponCode(code) {
            let newCode = code.replace(/[^a-zA-Z0-9]/g, '');
            newCode = newCode.toUpperCase();
            return newCode;
        },
		removeCoupon(coupon) {
			this.cartStore.appliedCoupons = this.cartStore.appliedCoupons.filter(c => c.code !== coupon.code);
		},
        applyCoupon() {
            this.loading = true;
            let currentCoupon = this.$refs['coupon-input'].currentValue;
            if(currentCoupon) {
                currentCoupon = this.sanitizeCouponCode(currentCoupon);
                if(currentCoupon && currentCoupon.length >= 4 && currentCoupon.length <= 16) {

					let coupons = this.cartStore.appliedCoupons.map(c => c.code);
					coupons.push(currentCoupon);
                    API.init().checkCoupons(this.menusStore.business.business_id, coupons, this.cartStore.getItemsFormatAPI(), this.cartStore.totalItems, this.menusStore.roomId, this.menusStore.orderMode, (r) => {
                        this.loading = false;
                        if(r && r.result && r.result === "OK") {
							this.cartStore.appliedCoupons = this.cartStore.normalizeAppliedCoupons(r.coupons, r.products, r.rooms);
							this.$refs['coupon-input'].currentValue = "";
                        } else {
							if(r && r.details) {
								if(r.details === "coupon_duplicate") {
									this.notificationStore.showNotification("coupon_duplicate");
									return;
								} else if(r.details === "coupon_min_order_value" && r.min_order_value) {
									this.notificationStore.showNotification("coupon_min_order_value", [this.currencyStore.readableCurrencyValue(r.min_order_value)]);
									return;
								} else if(r.details === "coupon_cant_stack") {
									this.notificationStore.showNotification("coupon_cant_stack");
									return;
								} else if(r.details === "coupon_once_per_user") {
									this.notificationStore.showNotification("coupon_already_used");
									return;
								} else if(r.details === "coupon_delivery" || r.details === "coupon_takeaway" || r.details === "coupon_qr") {
									this.notificationStore.showNotification("coupon_mode");
									return;
								} else if(r.details === "no_linked_products") {
									this.notificationStore.showNotification("no_linked_products");
									return;
								}
							}
                            this.notificationStore.showNotification("coupon_invalid");
                        }
                    });

                } else {
                    this.loading = false;
                    this.notificationStore.showNotification("coupon_invalid");
                }
            } else {
                this.loading = false;
                this.notificationStore.showNotification("coupon_invalid");
            }
        },
    },
	watch: {
		"menusStore.orderMode": function(newMode) {
			this.cartStore.appliedCoupons = this.cartStore.appliedCoupons.filter(c => {
				if(newMode === "delivery" && !c.delivery) {
					return false;
				} else if(newMode === "takeaway" && !c.takeaway) {
					return false;
				}
				return true;
			});
		},
		"cartStore.totalItems": function(newVal) {
			this.cartStore.appliedCoupons = this.cartStore.appliedCoupons.filter(c => {
				if(c.minimum_order_value && c.minimum_order_value > newVal) {
					return false;
				}
				return true;
			});
		},
		"cartStore.cart": {
			handler(newVal) {
				this.cartStore.appliedCoupons = this.cartStore.appliedCoupons.filter(c => {
					let found = true;
					if ((c.linked_products) || (c.linked_categories)) {
						found = false;
						for (let i = 0; i < c.products.length; i++) {
							if (newVal.find(p => p.menu_item_id === Number(c.products[i].item_id))) {
								found = true;
								break;
							}
						}
						for (let i = 0; i < c.categories.length; i++) {
							if (newVal.find(p => p.category_id === Number(c.categories[i].category_id))) {
								found = true;
								break;
							}
						}
					}
					return found;
				});
			},
			deep: true
		}
	}
}
</script>

<style scoped lang="scss">
.coupons-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .new-coupon-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--theme-bg1-color);
        border-radius: var(--rounded-corner);
        padding: 0 var(--padding-horizontal-s);
        width: 100%;

        .coupon-icon {
            width: 18px;
            height: 18px;

            &:deep(svg) {
                width: 100%;
                height: 100%;

                .fill {
                    fill: var(--theme-txt2-color);
                }

                .stroke {
                    stroke: var(--theme-txt2-color);
                }

            }
        }

        .coupon-input-container {
            flex: 1;

            &:deep(input) {
                padding: 4px 6px 4px 12px !important;
            }

        }

        .coupon-icon-add {
            width: 40px;
            height: 40px;
            padding: 12px;
            cursor: pointer;
            transition: opacity .2s ease-in-out;

            &:deep(svg) {
                width: 100%;
                height: 100%;

                .fill {
                    fill: var(--business-color);
                }

                .stroke {
                    stroke: var(--business-color);
                }

            }
        }

        &.loading {

            .coupon-icon-add {
                pointer-events: none;
                opacity: .35;
            }

        }
    }

	.explain-coupon {
		font-size: var(--font-size-s);
		color: var(--theme-txt2-color);
	}

	.coupon-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: var(--theme-bg1-color);
		border-radius: var(--rounded-corner);
		padding: 0 var(--padding-horizontal-s);
		width: 100%;
		border: 2px solid var(--business-color);

		.coupon-icon {
			width: 18px;
			height: 18px;

			&:deep(svg) {
				width: 100%;
				height: 100%;

				.fill {
					fill: var(--theme-txt2-color);
				}

				.stroke {
					stroke: var(--theme-txt2-color);
				}

			}
		}

		.coupon-code {
			flex: 1;
			font-size: var(--font-size-s);
			font-weight: var(--font-weight-bold);
			color: var(--theme-txt1-color);
			padding: 12px;
		}

		.coupon-value {
			flex-shrink: 0;
			font-size: var(--font-size);
			font-weight: 700;
			color: var(--theme-txt1-color);
			padding-right: 14px;
		}

		.coupon-delete {
			flex-shrink: 0;
			width: 16px;
			height: 16px;
			cursor: pointer;
			margin-right: var(--padding-horizontal-s);
			opacity: 0.8;

			&:deep(svg) {
				width: 100%;
				height: 100%;

				.fill {
					fill: var(--theme-txt2-color);
				}

				.stroke {
					stroke: var(--theme-txt2-color);
				}

			}
		}
	}
}
</style>
<template>
	<div class="item-list-product" @click="clickItem">
		<div class="product-title"><span class="quantity" :class="{shown: quantity > 0}">x{{ quantity+" " }}</span><span class="product-title-span">{{ product.product_nameTranslation }}</span></div>
		<div class="product-actions">
			<div class="product-price" v-if="product.product_price && !hidePrices">+{{ currencyStore.readableCurrencyValue(product.product_price) }}</div>
			<div class="product-checkbox" v-if="checkbox && menusStore.orderMode !== 'none'"><MenuCheckbox :checked="checked" @toggle="toggleCheckbox" /></div>
			<div class="product-adder" v-else-if="menusStore.orderMode !== 'none'"><MenuAdders @change="changeQuantity" /></div>
		</div>
	</div>
</template>

<script>
import MenuCheckbox from "@/new-menu/components/generic/MenuCheckbox.vue";
import MenuAdders from "@/new-menu/components/order/MenuAdders.vue";
import {useCurrencyStore, useLanguageStore, useNotificationStore} from "@/shared/stores";
import {useCartStore, useMenusStore} from "@/new-menu/stores";

export default {
	setup() {
		let menusStore = useMenusStore();
		let cartStore = useCartStore();
		const notificationStore = useNotificationStore();
		const currencyStore = useCurrencyStore();
		return {menusStore, currencyStore, cartStore, notificationStore}
	},
	name: "ItemListProduct",
	components: {MenuCheckbox, MenuAdders},
	emits: ["toggle", "change"],
	props: {
		product: {
			type: Object,
			required: true,
		},
		checkbox: {
			type: Boolean,
			default: false
		},
		quantity: {
			type: Number,
			default: 0
		},
		hidePrices: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			checked: false
		}
	},
	methods: {
		toggleCheckbox(on) {
			if(on) {
				if(this.product.availableQuantity !== null && this.product.availableQuantity <= 0) {
					this.notificationStore.showNotification("product_unavailable", [this.product.product_nameTranslation]);
					return;
				} else if(this.product.warehouse && this.product.sku && this.cartStore.getItemsWithSkuQuantity(this.product.sku) >= this.menusStore.skusQuantities[this.product.sku]) {
					this.notificationStore.showNotification("product_unavailable_other_cart", [this.product.product_nameTranslation, this.menusStore.skusQuantities[this.product.sku]]);
					return;
				}
			}
			this.checked = on;
			this.$emit("toggle", on);
		},
		changeQuantity(v) {
			if(v > 0) {
				if(this.product.availableQuantity !== null && this.quantity + v > this.product.availableQuantity) {
					if(this.product.availableQuantity <= 0) {
						this.notificationStore.showNotification("product_unavailable", [this.product.product_nameTranslation]);
						return;
					} else {
						this.notificationStore.showNotification("product_unavailable_warehouse", [this.product.product_nameTranslation, this.product.availableQuantity]);
						return;
					}
				} else if(this.product.warehouse && this.product.sku && this.cartStore.getItemsWithSkuQuantity(this.product.sku) + this.quantity + v > this.menusStore.skusQuantities[this.product.sku]) {
					this.notificationStore.showNotification("product_unavailable_other_cart", [this.product.product_nameTranslation, this.menusStore.skusQuantities[this.product.sku]]);
					return;
				}
			}
			this.$emit('change', v);
		},
		clickItem() {
			if(this.menusStore.orderMode === 'none') return;
			if(this.checkbox) {
				this.toggleCheckbox(!this.checked);
			} else {
				this.changeQuantity(1);
			}
		}
	}
}
</script>

<style scoped lang="scss">
.item-list-product {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 8px var(--padding-horizontal);
	cursor: pointer;

	.product-title {
		display: flex;
		align-items: baseline;
		overflow: hidden;

		.quantity {
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--business-color);
			font-weight: 700;
			padding-right: 0;
			max-width: 0;
			overflow: hidden;
			white-space: nowrap;
			transition: max-width .2s linear, padding-right .2s linear;
			display: block;
			flex-shrink: 0;

			&.shown {
				max-width: 50px;
				padding-right: 6px;
			}

		}

		.product-title-span {
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--theme-txt1-color);
			@include truncateString(3);
		}

	}

	.product-actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-shrink: 0;
		gap: 12px;
		margin-left: 6px;

		.product-price {
			font-size: var(--font-size-s);
			line-height: var(--line-height-s);
			color: var(--theme-txt2-color);
		}

	}
}
</style>
<template>
	<div class="map-input-container">
		<div class="row centered flex-end input-container">
			<MenuTextInput
				ref="text-input"
				:placeholder="placeholder"
				:value="value"
				:class="{disabled: !ready}"
				class="address-input"
			/>
			<div class="second-row" :class="{showSecondRow: showCivic || showZip}">
				<MenuTextInput
					ref="street-number-input"
					:class="{showCivic}"
					class="input-optional"
					:label="getString('civic')"
					:placeholder="getString('civic')"
					@changed-text="changedCivic"
				/>
				<MenuTextInput
					ref="zip-number-input"
					:class="{showZip}"
					class="input-optional"
					:label="getString('zip')"
					:placeholder="getString('zip')"
					@changed-text="changedZip"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import {useLanguageStore, useScriptStore} from "@/shared/stores";
import MenuTextInput from "@/new-menu/components/generic/MenuTextInput.vue";
import {useCartStore} from "@/new-menu/stores";

export default {
	name: "MenuGoogleMapsInput",
	emits: ['changed-address'],
	components: {MenuTextInput},
	setup() {
		const scriptStore = useScriptStore();
		const cartStore = useCartStore();
		const languageStore = useLanguageStore();
		return {loadScript: scriptStore.loadScript, cartStore, getString: languageStore.getString};
	},
	data() {
		return {
			address: null,
			autocomplete: null,
			ready: false,
			showCivic: false,
			showZip: false,
			autocompleteOptions: {
				types: ["address"],
				componentRestrictions: {country: COUNTRY}
			},
			autocompleteFields: ["formatted_address", "address_components", "geometry"],
		}
	},
	props: {
		placeholder: {type: String, default: ""},
		value: {type: String, default: ""},
	},
	methods: {
		initMapsInput() {
			let input = this.$refs['text-input'].$refs['input'];
			this.autocomplete = new google.maps.places.Autocomplete(input, this.autocompleteOptions);
			this.autocomplete.setFields(this.autocompleteFields);
			this.initAutocomplete(addressData=>{
				this.$emit('changed-address', addressData);
			})
		},
		hasNecessaryFields(addressData) {
			let basic = addressData.via && addressData.provincia;
			if(!basic) {
				return false;
			}
			if(!addressData.civico) {
				if(this.$refs['street-number-input'] && this.$refs['street-number-input'].currentValue && this.$refs['street-number-input'].currentValue.length > 0) {
					addressData.civico = this.$refs['street-number-input'].currentValue;
				} else {
					return false;
				}
			}
			if(!addressData.zip) {
				if(this.$refs['zip-number-input'] && this.$refs['zip-number-input'].currentValue && this.$refs['zip-number-input'].currentValue.length > 0) {
					addressData.zip = this.$refs['zip-number-input'].currentValue;
				} else {
					return false;
				}
			}
			return addressData;
		},
		initAutocomplete(addressDataCallback) {

			google.maps.event.addListener(this.autocomplete, 'place_changed', ()=>{
		
				let place = this.autocomplete.getPlace();
		
				if(place && place.geometry) {

					let lat = place.geometry.location.lat();
					let lng = place.geometry.location.lng();
		
					let address = place.formatted_address;
					let addressData = this.addressDataFromPlace(place);

					this.activeMap = true;
					this.address = address;

					this.showCivic = !addressData.civico;
					this.showZip = !addressData.zip;
					addressData.lat = lat;
					addressData.lng = lng;
					this.currentData = this.hasNecessaryFields(addressData);
					if(this.currentData) {
						addressDataCallback({
							address: address,
							lat: lat,
							lng: lng,
							data: this.currentData
						});
					} else {
						this.currentData = addressData;
					}
				} else {
					this.address = null;
				}
		
			});
		},
		changedCivic(v) {
			if(this.currentData && v.length > 0 && v.length <= 10) {
				let newAddressData = this.hasNecessaryFields(this.currentData);
				if(newAddressData) {
					this.$emit('changed-address', {
						address: this.address,
						lat: newAddressData.lat,
						lng: newAddressData.lng,
						data: newAddressData
					});
				}
			}
		},
		changedZip(v) {
			if(this.currentData && v.length === 5) {
				let newAddressData = this.hasNecessaryFields(this.currentData);
				if(newAddressData) {
					this.$emit('changed-address', {
						address: this.address,
						lat: newAddressData.lat,
						lng: newAddressData.lng,
						data: newAddressData
					});
				}
			}
		},
		addressDataFromPlace(place) {
			let returningData = {};
			let locality = null;
			place.address_components.forEach(component => {

				if(component.types && component.types.length > 0) {
					if(component.types[0] === "administrative_area_level_1") {
						returningData.regione = component.long_name;
						if(COUNTRY !== 'IT') {
							if(!returningData?.provincia_codice) {
								returningData.provincia_codice = component.short_name;
							}
						}
					} else if(component.types[0] === "administrative_area_level_2") {
						returningData.provincia_codice = component.short_name;
						returningData.provincia = component.long_name;
					} else if(component.types[0] === "administrative_area_level_3") {
						returningData.comune = component.long_name;
					} else if(component.types[0] === "locality") {
						locality = component.long_name;
					} else if(component.types[0] === "route") {
						returningData.via = component.long_name;
					} else if(component.types[0] === "postal_code") {
						returningData.zip = component.long_name;
					} else if(component.types[0] === "street_number") {
						returningData.civico = component.long_name;
					}
				}

			});
			returningData.country = COUNTRY;

			if(COUNTRY !== 'IT') {
				returningData.provincia = returningData.provincia || locality || returningData.regione || null;
				returningData.comune = returningData.comune || returningData.provincia || null;
			}
			return returningData;
		},
		focus() {
			this.$refs['text-input'].focus();
		}
	},
	mounted() {
		if(this.cartStore.openCart) {
			this.loadScript('maps', ()=>{
				this.ready = true;
				this.initMapsInput();
			});
		}
	},
	watch: {
		"cartStore.openCart": function(val) {
			if(val) {
				this.loadScript('maps', ()=>{
					this.ready = true;
					this.initMapsInput();
				});
			}
		}
	}
}
</script>

<style lang="scss">
.pac-container {
	border-radius: var(--rounded-corner);
	border-top: none;

	&.pac-logo:after {
		display: none;
	}

	.pac-item {
		padding: 0 8px;
		background: var(--theme-bg1-color);

		&:first-child {
			border-top-left-radius: var(--rounded-corner);
			border-top-right-radius: var(--rounded-corner);
			border-top: none;
		}

		.pac-icon-marker {
			display: none;
		}

		span {
			font-size: var(--font-size-s);
			line-height: var(--line-height-s);
			color: var(--theme-txt1-color);
		}

		&.pac-item-selected {
			background: var(--theme-bg2-color);
		}
	}

}
</style>
<style lang="scss" scoped>
.map-input-container {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;

	> .row {
		flex-wrap: nowrap;

		.menu-text-input {
			width: 100%;
		}
	}

	.input-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.second-row {
		display: none;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		margin-top: 8px;
		gap: 8px;

		&.showSecondRow {
			display: flex;
		}

		.input-optional {
			display: none;
		}

		.showCivic, .showZip {
			display: block;
		}
	}

}
</style>
<template>
	<div class="items-container">
		<OrderItem v-for="item in cartStore.cart" :item="item" :key="item.temp_id" />
		<div class="extra-cost" v-if="deliveryCosts && !menusStore.business.options.hide_prices">
			<div class="extra-title">{{ languageStore.getString('delivery_costs') }}</div>
			<div class="extra-price">{{ deliveryCosts }}</div>
		</div>
		<div class="extra-cost" v-if="takeawayCosts && !menusStore.business.options.hide_prices">
			<div class="extra-title">{{ languageStore.getString('delivery_costs') }}</div>
			<div class="extra-price">{{ takeawayCosts }}</div>
		</div>
		<div class="extra-cost" v-if="courierPrice && !menusStore.business.options.hide_prices">
			<div class="extra-title">{{ languageStore.getString('courier_costs') }}</div>
			<div class="extra-price">{{ courierPrice }}</div>
		</div>
		<div class="extra-cost" v-if="weconstudioBolloPrice && !menusStore.business.options.hide_prices">
			<div class="extra-title">{{ languageStore.getString('bollo_tax') }}</div>
			<div class="extra-price">{{ weconstudioBolloPrice }}</div>
		</div>
		<div class="extra-cost" v-if="coverChargePrice && !menusStore.business.options.hide_prices">
			<div class="extra-title">{{ menusStore?.room?.cover_charge_nameTranslation || languageStore.getString('cover_charge') }}</div>
			<div class="extra-price">{{ coverChargePrice }}</div>
		</div>
		<div class="extra-cost" v-if="cartStore.appliedCoupons.length > 0 && !menusStore.business.options.hide_prices">
			<div class="extra-title">{{ languageStore.getString('discount') }}</div>
			<div class="extra-price">{{ "-"+currencyStore.readableCurrencyValue(cartStore.couponDiscount) }}</div>
		</div>
	</div>
</template>

<script>
import MenuAdders from "@/new-menu/components/order/MenuAdders.vue";
import {useCartStore, useMenusStore} from "@/new-menu/stores";
import {useCurrencyStore, useLanguageStore} from "@/shared/stores";
import OrderItem from "@/new-menu/components/order/OrderItem.vue";

export default {
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		const currencyStore = useCurrencyStore();
		return {cartStore, currencyStore, languageStore, menusStore}
	},
	name: "OrderSectionSummary",
	components: {MenuAdders, OrderItem},
	computed: {
		deliveryCosts() {
			return this.menusStore.orderMode === 'delivery' && this.menusStore.business.options.delivery_costs ? this.currencyStore.readableCurrencyValue(this.menusStore.business.options.delivery_costs) : null;
		},
		takeawayCosts() {
			return this.menusStore.orderMode === 'takeaway' && this.menusStore.business.options.takeaway_costs ? this.currencyStore.readableCurrencyValue(this.menusStore.business.options.takeaway_costs) : null;
		},
		courierPrice() {
			return this.cartStore.courierPrice ? this.currencyStore.readableCurrencyValue(this.cartStore.courierPrice) : null;
		},
		weconstudioBolloPrice() {
			if(this.menusStore.orderMode === "qr") {
				if(this.menusStore.weconstudioInfo && this.menusStore.weconstudioInfo.bollo_threshold && this.menusStore.weconstudioInfo.bollo_value) {
					let bolloThreshold = parseInt(this.menusStore.weconstudioInfo.bollo_threshold);
					if(this.cartStore.totalItems >= bolloThreshold) {
						return this.currencyStore.readableCurrencyValue(parseInt(this.menusStore.weconstudioInfo.bollo_value));
					}
				}
			}
			return null;
		},
		coverChargePrice() {
			if(this.menusStore.orderMode === "qr" && (this.menusStore?.room?.cover_charge_everytime || !this.cartStore.coverInfo) && this.menusStore?.room?.cover_charge) {
				if(this.menusStore?.room?.cover_charge_type) {
					return this.currencyStore.readableCurrencyValue(this.menusStore.room.cover_charge);
				} else if(this.cartStore.coverChargeNum > 0) {
					return this.currencyStore.readableCurrencyValue(this.cartStore.coverChargeNum * this.menusStore.room.cover_charge);
				}
			}
			return null;
		}
	}
}
</script>

<style scoped lang="scss">
.items-container {
	display: flex;
	flex-direction: column;

	.extra-cost {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 10px 0;
		border-bottom: 1px solid var(--theme-bg2-color);

		.extra-title {
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--theme-txt1-color);
			@include truncateString();
		}
		.extra-price {
			font-size: var(--font-size-s);
			line-height: var(--line-height-s);
			color: var(--theme-txt2-color);
			margin-right: 8px;
		}
	}

	div {

		&:last-child {
			border-bottom: none;
		}

	}
}
</style>
<template>
	<div class="order-ctas" :class="{mobile}">
		<div class="privacy-container" v-if="menusStore.business.options.force_privacy && menusStore.acceptPrivacy === null">
			<div class="privacy-title">{{ getString("data_processing") }}</div>
			<div class="privacy-accept" @click="privacyAcceptClick">
				<div class="privacy-text"><p><a @click.stop :href="privacyUrl" target="_blank">{{ getString("privacy_read_link") }}</a>{{ " "+getString('allow_marketing') }}</p></div>
				<div class="privacy-refuse" v-html="getIcon('iconX')" @click.stop="privacyRefuseClick"></div>
			</div>
		</div>
		<div class="ctas-container" v-else>
			<div class="cta-row-summary" v-if="!menusStore.business.options.hide_prices">
				<div class="order-mode-choose-container" v-if="['delivery', 'takeaway'].includes(menusStore.orderMode)">
					<div class="order-mode-choose" v-html="menusStore.orderMode === 'delivery' ? getIcon('iconDelivery') : getIcon('iconTakeaway')"></div>
					<div class="order-mode-choose-title">{{ menusStore.orderMode === 'delivery' ? getString('delivery') : getString('takeaway') }}</div>
				</div>
				<div class="orders-queue-container" v-else-if="menusStore.orderMode === 'qr' && menusStore.business.options.queue_active_menu && cartStore.ordersQueue !== null">
					<div class="orders-queue">
						<div class="orders-queue-icon" v-if="cartStore.ordersQueue > 0" v-html="getIcon('iconHourglass')"></div>
						<div class="orders-queue-title"><span v-if="cartStore.ordersQueue > 1" class="many-orders">{{ cartStore.ordersQueue }}</span>{{ ordersQueueTitle }}</div>
					</div>
				</div>
				<div v-else></div>
				<div class="order-total" v-if="!menusStore.business.options.hide_prices">{{ currencyStore.readableCurrencyValue(cartStore.grandTotal) }}</div>
			</div>
			<div class="cta-quick-actions">
				<MenuButton :title="actionsStore.primaryText" :logos-carousel="actionsStore.primaryLogos" @click="payPrimary" />
			</div>
			<div class="cta-other-actions" v-if="showCashButton || actionsStore.otherPaymentMethods.length > 0">
				<MenuButton outline :title="getString('cash')" class="button-cash" @click="payCash" v-if="showCashButton" />
				<MenuButton outline :title="getString('other_methods')" :logos-carousel="actionsStore.logosOther" @click="otherPaymentsActive = true" v-if="actionsStore.otherPaymentMethods.length > 0" />
			</div>
			<AppBottomSheet id="stripe-bottom-sheet" :forceWidth="bottomSheetWidth" :active="stripeActive" @toggling-popup="togglingStripe">
				<StripeCheckout v-if="menusStore.paymentMethodsAvailable.includes('stripe')" :client-secret="clientSecret" :order-key="orderKey" :redirect-url="redirectUrl" :payment-methods="paymentMethods" :amount="orderAmount" @remove-pm="removePm" />
			</AppBottomSheet>
			<AppBottomSheet id="other-payments-bottom-sheet" :forceWidth="bottomSheetWidth" :active="otherPaymentsActive" @toggling-popup="togglingOtherPayments">
				<OrderOtherCtas
					v-if="otherPaymentsActive"
					@checkout="payOtherMethods"
					:satispay="actionsStore.otherPaymentMethods.includes('satispay')"
					:klarna="actionsStore.otherPaymentMethods.includes('klarna')"
					:btc="actionsStore.otherPaymentMethods.includes('btc')"
				/>
			</AppBottomSheet>
			<AppBottomSheet id="terminal-bottom-sheet" :forceWidth="bottomSheetWidth" :active="terminalSheetActive" @toggling-popup="togglingTerminal">
				<div class="terminal-sheet-container" v-if="terminalSheetActive">
					<div class="send-to-terminal-button" @click="payTerminal">
						<div class="terminal-icon" v-html="getTerminalIcon()"></div>
						<div class="terminal-text">{{ getString('send_to_terminal') }}</div>
					</div>
					<div class="pay-online" @click="clickForcePayOnline">{{ getString('or_pay_online') }}</div>
					<div class="terminal-loader" :class="{hidden: !loadingTerminal}">
						<AppLoader :color="'#'+menusStore.business.color" v-if="loadingTerminal" />
					</div>
				</div>
			</AppBottomSheet>
			<AppBottomSheet id="satispay-bottom-sheet" :forceWidth="bottomSheetWidth" :active="satispayActive" @toggling-popup="togglingSatispay">
				<div class="container-checkout-satispay-app" v-if="satispayActive">
					<div class="container-button container-button-cta container-button-cta-satispay">
						<a :href="this.redirectUrl">
							<div class="input-button translate">{{ getString("pay_with")+" " }}<b>{{ getString("satispay") }}</b></div>
						</a>
					</div>
				</div>
			</AppBottomSheet>
		</div>
		<div class="ctas-loader" :class="{hidden: !loading}">
			<AppLoader :color="'#'+menusStore.business.color" v-if="loading" />
		</div>
	</div>
</template>

<script>
import MenuButton from "@/new-menu/components/generic/MenuButton.vue";
import {useCurrencyStore, useLanguageStore, useNotificationStore, useScriptStore, useSvgStore} from "@/shared/stores";
import {useActionsStore, useCartStore, useEvsStore, useMenusStore} from "@/new-menu/stores";
import AppLoader from "@/shared/components/AppLoader.vue";
import AppBottomSheet from "@/shared/components/AppBottomSheet.vue";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import StripeCheckout from "@/new-menu/components/order/StripeCheckout.vue";
import OrderOtherCtas from "@/new-menu/components/order/OrderOtherCtas.vue";
import {validateEmail} from "@/shared/helpers/strings";
import {getTerminalIcon} from "@/new-menu/helpers";

export default {
	setup() {
		let languageStore = useLanguageStore();
		let svgStore = useSvgStore();
		let menusStore = useMenusStore();
		let cartStore = useCartStore();
		let scriptStore = useScriptStore();
		let notificationStore = useNotificationStore();
		let evsStore = useEvsStore();
		const actionsStore = useActionsStore();
		const currencyStore = useCurrencyStore();
		return {getString: languageStore.getString, getIcon: svgStore.get, menusStore, currencyStore, cartStore, scriptStore, notificationStore, evsStore, actionsStore}
	},
	name: "OrderCtas",
	components: {OrderOtherCtas, StripeCheckout, AppBottomSheet, AppLoader, MenuButton},
	mixins: [WindowWidth],
	props: {
		mobile: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			loading: false,
			loadingTerminal: false,
			stripeActive: false,
			otherPaymentsActive: false,
			terminalSheetActive: false,
			satispayActive: false,
			clientSecret: null,
			orderKey: null,
			orderAmount: null,
			redirectUrl: null,
			paymentMethods: [],
		}
	},
	computed: {
		privacyUrl() {
			return this.menusStore.business.options.custom_privacy ? this.menusStore.business.options.custom_privacy : "/privacy/";
		},
		bottomSheetWidth() {
			if(this.windowWidth < 550) {
				return "100%";
			} else {
				return "550px";
			}
		},
		ordersQueueTitle() {
			if(this.cartStore.ordersQueue === 0) {
				return this.getString("orders_queue_empty");
			} else if(this.cartStore.ordersQueue === 1) {
				return this.getString("orders_queue_one");
			} else {
				return " "+this.getString("orders_queue_many");
			}
		},
		showCashButton() {
			return this.menusStore.paymentMethodsAvailable.includes('cash') && this.actionsStore.primaryPaymentMethod !== 'cash';
		},
	},
	methods: {
		getTerminalIcon() {
			return getTerminalIcon();
		},
		privacyAcceptClick() {
			this.menusStore.acceptPrivacy = true;
			this.menusStore.saveData();
		},
		privacyRefuseClick() {
			this.menusStore.acceptPrivacy = false;
			this.menusStore.saveData();
		},
		removePm(v) {
			this.paymentMethods = this.paymentMethods.filter(pm => pm.id !== v);
		},
		togglingStripe(active) {
			if(!active) {
				this.stripeActive = active;
			}
		},
		togglingOtherPayments(active) {
			if(!active) {
				this.otherPaymentsActive = active;
			}
		},
		togglingTerminal(active) {
			if(!active) {
				this.terminalSheetActive = active;
			}
		},
		togglingSatispay(active) {
			if(!active) {
				this.satispayActive = active;
			}
		},
		clickForcePayOnline() {
			this.cartStore.terminalIdToUse = null;
			this.terminalSheetActive = false;
			this.payStripe("skip");
		},
		payPrimary(skipTerminal = null) {
			if(this.cartStore.terminalId && skipTerminal !== "skip") {
				this.terminalSheetActive = true;
				return;
			}
			if(this.actionsStore.primaryPaymentMethod === "stripe") {
				this.payStripe();
			} else if(this.actionsStore.primaryPaymentMethod === "cash") {
				this.payCash();
			} else if(this.actionsStore.primaryPaymentMethod === "klarna") {
				this.payOtherMethods("klarna");
			} else if(this.actionsStore.primaryPaymentMethod === "satispay") {
				this.payOtherMethods("satispay");
			} else if(this.actionsStore.primaryPaymentMethod === "btc") {
				this.payOtherMethods("btc");
			} else {
				this.payStripe();
			}
		},
		payCash() {
			this.cartStore.requireEmail(() => {
				this.evsStore.enqueueEvent('click', 'pay-cash');
				this.loading = true;

				this.cartStore.checkout("cash", (res) => {
					this.loading = false;
					if(res) {
						if(res.order_link) {
							let tknTmpPart = res.order_tkn_temp ? '&tknTmp=' + res.order_tkn_temp : '';
							if (TEST && LOCAL) {
                                window.location.href = "http://" + window.location.host + '/order/?id=' + res.order_link + tknTmpPart;
							} else {
								window.location.href = "https://" + window.location.host + '/order/?id=' + res.order_link + tknTmpPart;
							}
						} else {
							this.notificationStore.showNotification("generic_fail");
						}
					}
				});
			});
		},
		payOtherMethods(method) {
			if(method === "satispay" || method === "klarna" || (method === "btc" && this.cartStore.grandTotal === 0)) {
				this.cartStore.requireEmail(() => {
					this.payOtherMethodsPostAction(method);
				});
			} else {
				this.payOtherMethodsPostAction(method);
			}
		},
		payOtherMethodsPostAction(method) {
			this.evsStore.enqueueEvent('click', 'pay-'+method);

			this.loading = true;
			this.otherPaymentsActive = false;
			this.cartStore.checkout(method, (res) => {
				if(!res) {
					this.loading = false;
					return;
				}
                if(res.order_link && this.cartStore.grandTotal === 0) {
                    let tknTmpPart = res.order_tkn_temp ? '&tknTmp=' + res.order_tkn_temp : '';
                    if (TEST && LOCAL) {
                        window.location.href = "http://" + window.location.host + '/order/?id=' + res.order_link + tknTmpPart;
                    } else {
                        window.location.href = "https://" + window.location.host + '/order/?id=' + res.order_link + tknTmpPart;
                    }
                    return;
                }
				if(method === "btc") {
					if(res.invoice_url) {
						window.location.href = res.invoice_url;
					} else {
						this.notificationStore.showNotification("generic_fail");
						this.loading = false;
					}
				} else if(method === "klarna") {
					if(res.payment_intent && res.payment_intent.client_secret && res.order_url) {
						if(res.customer_email && res.customer_email !== this.cartStore.customerEmail) {
							this.cartStore.customerEmail = res.customer_email;
							this.menusStore.saveData();
						}
						this.clientSecret = res.payment_intent.client_secret;
						this.redirectUrl = res.order_url;
						if(this.scriptStore.loadedScripts.includes("stripe")) {
							this.confirmKlarna();
						} else {
							this.scriptStore.loadScript("stripe", () => {
								this.confirmKlarna();
							});
						}
					} else {
						this.notificationStore.showNotification("generic_fail");
						this.loading = false;
					}
				} else if(method === "satispay") {
					if(res.invoice_url) {
						this.redirectUrl = res.invoice_url;
						this.satispayActive = true;
						this.loading = false;
					} else {
						this.notificationStore.showNotification("generic_fail");
						this.loading = false;
					}
				}
			});
		},
		confirmKlarna() {

			let stripe = Stripe(STRIPE_PK);
			stripe.confirmKlarnaPayment(
				this.clientSecret,
				{
					payment_method: {
						billing_details: {
							email: this.cartStore.customerEmail,
							address: {
								country: COUNTRY,
							},
						},
					},
					receipt_email: this.cartStore.customerEmail,
					return_url: this.redirectUrl,
				}
			).then((error) => {
				if (error) {
					this.loading = false;
					this.notificationStore.showNotification("generic_fail");
				}
			});

		},
		payStripe() {

			this.evsStore.enqueueEvent('click', 'pay-stripe');

			if(!this.scriptStore.loadedScripts.includes("stripe")) {
				this.notificationStore.showNotification("loading_stripe");
				this.scriptStore.loadScript("stripe", () => {});
				return;
			}

            if(this.cartStore.grandTotal === 0) {

                this.cartStore.requireEmail(() => {
                    this.payStripePostEmail();
                });

            } else {
                this.payStripePostEmail();
            }

		},
        payStripePostEmail() {

            this.loading = true;
            this.cartStore.checkout("stripe", (res) => {
                this.loading = false;
                if(res && res.payment_intent && res.payment_intent.client_secret && res.payment_intent.amount) {
                    if(res.customer_email && res.customer_email !== this.cartStore.customerEmail) {
                        this.cartStore.customerEmail = res.customer_email;
                        this.menusStore.saveData();
                    }
                    this.clientSecret = res.payment_intent.client_secret;
                    this.redirectUrl = res.order_url;
                    this.orderKey = res.order_key;
                    this.orderAmount = res.payment_intent.amount;
                    this.paymentMethods = res.payment_methods ? res.payment_methods : [];
                    this.stripeActive = true;
                } else if(res && res.order_link && this.cartStore.grandTotal === 0) {
                    let tknTmpPart = res.order_tkn_temp ? '&tknTmp=' + res.order_tkn_temp : '';
                    if (TEST && LOCAL) {
                        window.location.href = "http://" + window.location.host + '/order/?id=' + res.order_link + tknTmpPart;
                    } else {
                        window.location.href = "https://" + window.location.host + '/order/?id=' + res.order_link + tknTmpPart;
                    }
                }
            });
        },
		payTerminal() {

			this.evsStore.enqueueEvent('kiosk', 'pay-stripe');

			this.cartStore.requireEmail(() => {

				this.cartStore.terminalIdToUse = this.cartStore.terminalId;
				this.loadingTerminal = true;
				this.cartStore.checkout("kiosk", (res) => {
					if(res && res.terminal_request) {
						if(!res.terminal_request.error && res.order_url && res.order_key) {

							this.redirectUrl = res.order_url;
							this.orderKey = res.order_key;

							let baseUrl;
							if (TEST && LOCAL) {
                                baseUrl = "http://"+window.location.host;
							} else {
								baseUrl = "https://"+window.location.host;
							}
							window.location.href = baseUrl+"/order/waiting-room-generic.php?r="+encodeURIComponent(this.redirectUrl)+"&o="+this.orderKey+"&m=complete_terminal";

						} else {
							this.notificationStore.showNotification("generic_fail");
							this.terminalSheetActive = false;
							this.loadingTerminal = false;
							this.cartStore.terminalIdToUse = null;
						}
					} else if(res && res.order_link && this.cartStore.grandTotal === 0) {
                        let tknTmpPart = res.order_tkn_temp ? '&tknTmp=' + res.order_tkn_temp : '';
                        if (TEST && LOCAL) {
                            window.location.href = "http://" + window.location.host + '/order/?id=' + res.order_link + tknTmpPart;
                        } else {
                            window.location.href = "https://" + window.location.host + '/order/?id=' + res.order_link + tknTmpPart;
                        }
                    } else {
						this.terminalSheetActive = false;
						this.loadingTerminal = false;
						this.cartStore.terminalIdToUse = null;
					}
				});
			});
		},
	},
	mounted() {
		let scripts = ["captcha"];
		if(this.menusStore.paymentMethodsAvailable.includes("stripe")) {
			scripts.push("stripe");
		}
		this.scriptStore.loadScriptsParallel(scripts, () => {

		});

	}
}
</script>

<style lang="scss">
#stripe-bottom-sheet {
	background-color: var(--theme-txt1-alpha60-color);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;

	&.hidden {
		opacity: 0;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);
		background-color: var(--theme-txt1-alpha60-color);
	}

	.bottom-sheet {
		padding: 0;
		background: var(--theme-bg2-color);
		border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;

		.bottom-sheet-measurer {
			border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		}
	}
}
#other-payments-bottom-sheet, #satispay-bottom-sheet, #terminal-bottom-sheet {
	background-color: var(--theme-txt1-alpha60-color);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;

	&.hidden {
		opacity: 0;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);
		background-color: var(--theme-txt1-alpha60-color);
	}

	.bottom-sheet {
		padding: 0;
		background: var(--theme-bg1-color);
		border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;

		.bottom-sheet-measurer {
			border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		}
	}
}
</style>
<style scoped lang="scss">
.container-checkout-satispay-app {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100px;
	overflow: hidden;
	border-top-left-radius: var(--rounded-corner);
	border-top-right-radius: var(--rounded-corner);
	width: 100%;

	.container-button-cta-satispay {
		margin: auto;

		.input-button {
			text-transform: none;
			opacity: 1;
			background-color: rgb(249, 76, 67) !important;
			border: 1px solid rgb(249, 76, 67);
			line-height: 1.5;
			border-radius: var(--rounded-corner);
			transition-delay: 0s;
			transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
			text-decoration: none;
			width: fit-content;
			font-size: var(--font-size-l);
			color: rgba(255, 255, 255, 0.8);
			padding: 12px 12px 12px 50px;
			position: relative;

			b {
				color: white;
			}

			&::after {
				content: '';
				height: 33px;
				width: 33px;
				border-radius: var(--rounded-corner);
				background-image: url("https://img.qromo.io/img/icon-satispay-square.svg");
				background-position: center;
				background-repeat: no-repeat;
				display: block;
				position: absolute;
				transform: translateY(-50%);
				top: 50%;
				left: 9px;
			}

			&:hover {
				transform: none;
				box-shadow: rgb(252 170 166) 0px 0.1rem 0.3rem 0.1rem;
			}
		}
	}

}

.terminal-sheet-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: var(--padding-vertical);
	padding: var(--padding-vertical) var(--padding-horizontal);
	position: relative;

	.terminal-loader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--theme-txt1-alpha60-color);
		backdrop-filter: blur(6px);
		-webkit-backdrop-filter: blur(6px);
		transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;
		visibility: visible;
		opacity: 1;
		border-radius: calc(var(--rounded-corner) - 3px);
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;

		&.hidden {
			opacity: 0;
			backdrop-filter: blur(0px);
			-webkit-backdrop-filter: blur(0px);
			visibility: hidden;
		}
	}

	.send-to-terminal-button {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 150px;
		width: 100%;
		background-color: var(--business-color);
		border-radius: var(--rounded-corner);
		cursor: pointer;

		.terminal-icon {
			height: 70px;
			width: 70px;
			margin-right: var(--padding-horizontal);

			&:deep(svg) {
				width: 100%;
				height: 100%;

				.fill {
					fill: var(--business-text-color);
				}

				.stroke {
					stroke: var(--business-text-color);
				}
			}
		}

		.terminal-text {
			font-size: var(--font-size-l);
			line-height: var(--line-height-l);
			color: var(--business-text-color);
			font-weight: 700;
		}
	}

	.pay-online {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		font-size: var(--font-size);
		line-height: var(--line-height);
		color: var(--theme-txt1-color);
		border: 1px solid var(--business-color);
		border-radius: var(--rounded-corner);
		padding: var(--padding-vertical) var(--padding-horizontal);
		cursor: pointer;
	}
}

.order-ctas {
	width: 100%;
	padding: var(--padding-vertical) var(--padding-horizontal);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	min-height: 50px;
	background-color: var(--theme-bg1-color);
	border-radius: var(--rounded-corner);
	border: 2px solid var(--business-color);
	position: relative;

	.ctas-loader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--theme-txt1-alpha60-color);
		backdrop-filter: blur(6px);
		-webkit-backdrop-filter: blur(6px);
		transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;
		visibility: visible;
		opacity: 1;
		border-radius: calc(var(--rounded-corner) - 3px);

		&.hidden {
			opacity: 0;
			backdrop-filter: blur(0px);
			-webkit-backdrop-filter: blur(0px);
			visibility: hidden;
		}
	}

	&.mobile {
		border-radius: var(--rounded-corner-l);
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 10;

		.ctas-loader {
			border-radius: calc(var(--rounded-corner-l) - 2px);
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.privacy-container {
		display: flex;
		flex-direction: column;

		.privacy-title {
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--theme-txt1-color);
			font-weight: 700;
			margin-bottom: 8px;
		}

		.privacy-accept {
			display: flex;
			flex-direction: column;
			background-color: var(--theme-bg1-color);
			border-radius: var(--rounded-corner);
			border: 1px solid var(--theme-txt1-color);
			padding: 8px var(--padding-horizontal-s);
			cursor: pointer;
			transition: background-color 0.3s ease-in-out;
			position: relative;

			.privacy-refuse {
				position: absolute;
				right: 12px;
				top: 12px;
				cursor: pointer;
				display: flex;

				&:deep(svg) {
					width: 10px;
					height: 10px;

					.fill {
						fill: var(--theme-txt1-color);
					}

					.stroke {
						stroke: var(--theme-txt1-color);
					}
				}
			}

			&:hover {
				background-color: var(--theme-bg2-color);
			}

			.privacy-text {
				display: flex;
				margin-left: 20px;
				position: relative;
				padding-right: 18px;

				&:before {
					content: '';
					height: 15px;
					width: 15px;
					background-color: var(--theme-bg2-color);
					position: absolute;
					left: -24px;
					top: calc(50% - 8px);
					border: 1px solid var(--theme-txt1-color);
					border-radius: var(--rounded-corner-s);
				}

				a {
					text-decoration: none;
					font-weight: 500;
					font-size: var(--font-size-s);
					line-height: var(--line-height-s);
					color: var(--business-color) !important;

					&:after {
						display: none;
					}
				}

				p {
					font-size: var(--font-size-s);
					line-height: var(--line-height-s);
					color: var(--theme-txt2-color);
					margin: 0;
				}

			}
		}
	}

	.ctas-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 8px;
		position: relative;


		.cta-row-summary {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;

			.order-mode-choose-container {
				display: flex;
				align-items: center;
				gap: 8px;

				.order-mode-choose {
					display: flex;
					align-items: center;

					&:deep(svg) {
						width: 20px;
						height: 20px;

						.fill {
							fill: var(--business-color);
						}

						.stroke {
							stroke: var(--business-color);
						}
					}
				}

				.order-mode-choose-title {
					font-family: var(--font-family);
					font-size: var(--font-size-s);
					line-height: var(--line-height-s);
					color: var(--theme-txt1-color);
				}
			}

			.orders-queue-container {
				display: flex;
				align-items: center;

				.orders-queue {
					display: flex;
					align-items: center;

					.orders-queue-icon {
						display: flex;
						align-items: center;
						animation: rotateWithPause 6s infinite ease reverse;

						&:deep(svg) {
							width: 14px;
							height: 14px;

							.fill {
								fill: var(--business-color);
							}

							.stroke {
								stroke: var(--business-color);
							}
						}

						@keyframes rotateWithPause {
							0% {
								transform: rotate(0deg);
							}
							20% {
								transform: rotate(0deg);
							}
							30% {
								transform: rotate(180deg);
							}
							70% {
								transform: rotate(180deg);
							}
							80% {
								transform: rotate(360deg);
							}
							100% {
								transform: rotate(360deg);
							}
						}
					}

					.orders-queue-title {
						font-family: var(--font-family);
						font-size: var(--font-size-s);
						line-height: var(--line-height-s);
						color: var(--theme-txt2-color);
						margin-left: 6px;

						.many-orders {
							font-weight: 700;
							font-size: var(--font-size-s);
							line-height: var(--line-height-s);
							color: var(--business-color);
						}
					}
				}
			}

			.order-total {
				font-family: var(--font-family);
				font-size: var(--font-size-l);
				line-height: var(--line-height-l);
				color: var(--theme-txt1-color);
				font-weight: 700;
			}
		}

		.cta-quick-actions {
			display: flex;
			width: 100%;
			gap: 8px;

			.menu-button {
				flex-grow: 1;
				padding: 8px var(--padding-horizontal);
				min-height: 44px;

			}

		}

		.cta-other-actions {
			display: flex;
			width: 100%;
			gap: 8px;

			.menu-button {
				flex-grow: 1;
				padding: 6px var(--padding-horizontal);
				min-height: 44px;

				&.button-cash {
					width: 70px;
				}
			}
		}
	}
}
</style>
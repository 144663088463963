<template>
	<div class="order" :class="{mobile}">
		<div class="order-body">
			<div class="order-section" v-if="menusStore.shouldShowModeSwitch">
				<MenuTabs :items="switchModeItems" @switched="switchModeDelivery" :active-item="menusStore.forceTakeaway ? 1 : 0" />
			</div>
			<div class="order-section order-summary" v-if="cartStore.cart.length > 0">
				<div class="section-title">{{ getString("order_summary") }}</div>
				<div class="section-content">
					<OrderSectionSummary />
				</div>
			</div>
			<div class="order-section" v-if="menusStore.business.options.cross_selling && suggestedItems.length > 0">
				<div class="section-title">{{ getString("popular_choices") }}</div>
				<div class="section-content cross-selling-category-container">
					<div class="cross-selling-category">
						<MenuCategoryItem @click="crossSellClick" :grid="false" cross-sell carousel no-picture v-for="item in suggestedItems" :item-id="item.menu_item_id" :category-id="item.category_id" />
					</div>
				</div>
			</div>
			<div id="order-section-time-picker" class="order-section order-section-time-picker" :class="{highlight: cartStore.sectionTimePickerHighlight, hideCalendar}" v-if="timePickerShow">
				<div class="section-title">{{ getString("order_time") }}</div>
				<DatePicker ref="date-picker" v-model="cartStore.notInPlaceOrderDate" mode="date" :min-date='currentDate' :max-date="maxDate" color="business" @update:modelValue="changedCalendarDate">
					<template #default="{ togglePopover }">
						<MenuTabs :items="[getString('ASAP'), getString('schedule')]" v-if="asapPickerShow" @switched="switchDeliveryAsap" :active-item="cartStore.deliveryAsap ? 0 : 1" />
						<div class="time-picker-container" :class="{noHeader: !asapPickerShow}">
							<div class="calendar-container" @click="togglePopover()">
								<div class="calendar-icon" v-html="getIcon('iconCalendar')"></div>
								<div class="calendar-text">{{ formatDateOnly(cartStore.notInPlaceOrderDate) }}</div>
							</div>
						</div>
					</template>
				</DatePicker>
				<div class="hour-picker-container">
					<HourPicker ref="hour-picker" :date="cartStore.notInPlaceOrderDate" :times="menusStore.orderMode === 'delivery' ? menusStore.deliveryTimes : menusStore.takeawayTimes" />
				</div>
			</div>
			<div id="order-section-not-in-place-data" class="order-section" :class="{highlight: cartStore.sectionNotInPlaceDataHighlight}" v-if="['delivery', 'takeaway'].includes(menusStore.orderMode)">
				<div class="section-title">{{ getString("order_data") }}</div>
				<div class="section-content section-content-data-input">
					<MenuTextInput ref="input-order-data-name" :placeholder="getString('name')" @changed-text="changeCustomerName" @pressed-enter="$refs['input-order-data-phone'].focus()" :value="cartStore.customerName" @focus="cartStore.alertSectionNotInPlaceData(false)" />
					<div class="section-content-input section-phone-input">
						<PhonePrefixInput />
						<MenuTextInput ref="input-order-data-phone" :placeholder="getString('phone_number')" @changed-text="changePhone" @pressed-enter="$refs['input-order-data-address'].focus()" :value="cartStore.customerPhone" @focus="cartStore.alertSectionNotInPlaceData(false)" />
					</div>
					<MenuGoogleMapsInput ref="input-order-data-address" v-if="menusStore.orderMode === 'delivery'" :placeholder="getString('address')" @changed-address="changeAddress" :value="prefillAddress" @focus="cartStore.alertSectionNotInPlaceData(false)" />
					<MenuTextInput ref="input-order-data-address-details" v-if="showAddressDetails" :placeholder="getString('address_details')" @changed-text="changeAddressDetails" :value="cartStore.customerAddressDetails" @focus="cartStore.alertSectionNotInPlaceData(false)" />
				</div>
			</div>
			<div id="order-section-couriers" class="order-section order-summary" v-if="showCouriers" :class="{highlight: cartStore.sectionCourierHighlight}">
				<div class="section-title">{{ getString("couriers") }}</div>
				<div class="section-subtitle" v-if="menusStore.business.options.delivery_courier_free_shipping !== null">{{ getString('free_shipping_above', [currencyStore.readableCurrencyValue(menusStore.business.options.delivery_courier_free_shipping)]) }}</div>
				<div class="section-content">
					<CouriersList />
				</div>
			</div>
			<div id="order-section-cover" class="order-section" v-if="menusStore.orderMode === 'qr' && menusStore?.room?.cover_charge !== null && !menusStore?.room?.cover_charge_type && (menusStore?.room?.cover_charge_everytime || !cartStore.coverInfo)">
				<div class="section-title">{{ getString("how_many_people") }}</div>
				<div class="section-subtitle">{{ getString("how_many_people_explain") }}</div>
				<div class="section-content-input">
					<MenuTextInput ref="input-cover-num" :placeholder="getString('number_of_people')" @changed-text="changeCoverNumber" @pressed-enter="focusNameTablePhoneIfAvailable" :value="cartStore.coverChargeNum+''" type="number" @focus="cartStore.scrollToSectionCover()" />
				</div>
			</div>
			<div id="order-section-name" class="order-section" :class="{highlight: cartStore.sectionNameHighlight}" v-if="menusStore.orderMode === 'qr' && ['name', 'area'].includes(menusStore.qrType)">
				<div class="section-title">{{ getString("your_name") }}</div>
				<div class="section-content-input">
					<MenuTextInput ref="input-name" :placeholder="getString('write_your_name')" @changed-text="changeCustomerName" @pressed-enter="focusPhoneIfAvailable" :value="cartStore.customerName" @focus="cartStore.alertSectionName(false)" />
				</div>
			</div>
			<div id="order-section-table-number" class="order-section" :class="{highlight: cartStore.sectionTableNumberHighlight}" v-if="menusStore.orderMode === 'qr' && ['none'].includes(menusStore.qrType)">
				<div class="section-title">{{ getString("table_number") }}</div>
				<div class="section-content-input">
					<MenuTextInput ref="input-table-number" :placeholder="getString('table_number')" @changed-text="changeTableNumber" @pressed-enter="focusPhoneIfAvailable" :value="cartStore.tableNumber ? cartStore.tableNumber+'' : ''" @focus="cartStore.alertSectionTableNumber(false)" />
				</div>
			</div>
			<div id="order-section-phone-number" class="order-section" :class="{highlight: cartStore.sectionPhoneHighlight}" v-if="menusStore.orderMode === 'qr' && menusStore?.room?.enabled_sms">
				<div class="section-title with-subtitle">{{ getString("phone_number") }}</div>
				<div class="section-subtitle">{{ getString("phone_number_explain") }}</div>
				<MenuTabs v-if="false" :items="['SMS', 'Whatsapp']" @switched="v => cartStore.notificationType = v === 1 ? 'whatsapp' : 'sms'" />
				<div class="section-content-input section-phone-input mt-4">
					<PhonePrefixInput />
					<MenuTextInput ref="input-phone" :placeholder="getString('phone_number')" @changed-text="changePhone" :value="cartStore.customerPhone" @focus="cartStore.alertSectionPhone(false)" />
				</div>
			</div>
			<div id="order-section-buffer" class="order-section" :class="{highlight: cartStore.sectionBufferHighlight}" v-if="menusStore.business.options.buffer_time && menusStore.business.options.buffer_customers && menusStore.roomTableId !== null">
				<div class="section-title">{{ getString("last_one_to_order") }}</div>
				<div class="section-content-input section-buffer-buttons">
					<MenuButton :outline="cartStore.buffering" :title="getString('yes')" @click="cartStore.buffering = false" />
					<MenuButton :outline="!cartStore.buffering" :title="getString('no')" @click="cartStore.buffering = true" />
				</div>
				<p class="buffer-explain">{{ cartStore.buffering ? getString('buffering_explain') : getString('no_buffering_explain') }}</p>
			</div>
			<div id="order-section-coupons" class="order-section" :class="{highlight: cartStore.sectionCouponsHighlight}" v-if="menusStore.business.options.coupons && !menusStore.business.options.hide_prices">
				<div class="section-title">{{ getString("do_you_have_coupon_code") }}</div>
				<div class="section-content-input">
                    <CouponsList />
				</div>
			</div>
			<div id="order-section-notes" class="order-section" v-if="!menusStore.business.options.hide_notes">
				<div class="section-title">{{ getString("notes") }}</div>
				<div class="section-content-input">
					<MenuTextAreaInput :placeholder="getString('additional_notes')" @changed-text="changeNotes" @focus="cartStore.scrollToSectionNotes()" />
				</div>
			</div>
		</div>
		<OrderCtas :mobile="mobile" />
	</div>
</template>

<script>
import OrderSectionSummary from "@/new-menu/components/order/OrderSectionSummary.vue";
import MenuAdders from "@/new-menu/components/order/MenuAdders.vue";
import {useCartStore, useEvsStore, useMenusStore} from "@/new-menu/stores";
import {useCurrencyStore, useLanguageStore, useSvgStore} from "@/shared/stores";
import MenuTabs from "@/new-menu/components/generic/MenuTabs.vue";
import MenuTextInput from "@/new-menu/components/generic/MenuTextInput.vue";
import MenuTextAreaInput from "@/new-menu/components/generic/MenuTextAreaInput.vue";
import PhonePrefixInput from "@/new-menu/components/order/PhonePrefixInput.vue";
import OrderCtas from "@/new-menu/components/order/OrderCtas.vue";
import MenuGoogleMapsInput from "@/new-menu/components/generic/MenuGoogleMapsInput.vue";
import AppTextInput from "@/shared/components/AppTextInput.vue";
import {DatePicker} from "v-calendar";
import {formatDateOnly} from "@/shared/helpers/dates";
import HourPicker from "@/new-menu/components/order/HourPicker.vue";
import CouriersList from "@/new-menu/components/order/CouriersList.vue";
import CouponsList from "@/new-menu/components/order/CouponsList.vue";
import MenuButton from "@/new-menu/components/generic/MenuButton.vue";
import MenuCategoryItem from "@/new-menu/components/MenuCategoryItem.vue";

export default {
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		let svgStore = useSvgStore();
		let evsStore = useEvsStore();
		const currencyStore = useCurrencyStore();
		return {cartStore, currencyStore, evsStore, getString: languageStore.getString, menusStore, getIcon: svgStore.get, formatDateOnly}
	},
	name: "OrderContainer",
	components: {
		MenuButton,
		DatePicker,
		AppTextInput,
		HourPicker,
		CouriersList,
        CouponsList,
		PhonePrefixInput, MenuTextInput, MenuTextAreaInput, MenuTabs, MenuAdders, OrderSectionSummary, OrderCtas, MenuGoogleMapsInput, MenuCategoryItem},
	props: {
		mobile: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			currentDate: new Date(),
			showAddressDetails: false,
			prefillAddress: null,
			suggestedItems: [],
			crossSellClicking: false,
		}
	},
	computed: {
		showCouriers() {
			return this.menusStore.orderMode === 'delivery' && this.menusStore.business.options.delivery_courier;
		},
		maxDate() {
			let date = new Date(this.currentDate);
			let days = this.menusStore.orderMode === 'delivery' ? (this.menusStore.business.options.delivery_future_days || 0) : (this.menusStore.business.options.takeaway_future_days || 0);
			date.setDate(date.getDate() + days);
			return date;
		},
		switchModeItems() {
			let deliveryWord = this.menusStore.business.type === "other" ? this.getString('delivery_other') : this.getString('delivery');
			let takeawayWord = this.menusStore.business.type === "other" ? this.getString('takeaway_other') : this.getString('takeaway');
			return [deliveryWord, takeawayWord];
		},
		timePickerShow() {
			if(this.menusStore.orderMode === 'delivery') {
				return this.menusStore.isDeliveryFutureAvailable;
			} else if(this.menusStore.orderMode === 'takeaway') {
				return true;
			}
			return false;
		},
		asapPickerShow() {
			return this.menusStore.orderMode === 'delivery' && this.menusStore.isDeliveryNowAvailable;
		},
		hideCalendar() {
			return this.menusStore.orderMode === 'delivery' && this.cartStore.deliveryAsap;
		}
	},
	methods: {
		crossSellClick() {
			this.crossSellClicking = true;
		},
		switchModeDelivery(v) {
			this.menusStore.forceTakeaway = v === 1;
			this.evsStore.enqueueEvent("click", this.menusStore.forceTakeaway ? "toggle-takeaway" : "toggle-delivery");
		},
		switchDeliveryAsap(v) {
			this.cartStore.deliveryAsap = v !== 1;
			this.evsStore.enqueueEvent("click", "delivery-mode-switch", this.menusStore.deliveryAsap ? "asap" : "schedule");
		},
		changeNotes(text) {
			this.cartStore.notes = text.length > 0 ? text : null;
		},
		changeCoverNumber(num) {
			this.cartStore.coverChargeNum = num && parseInt(num) > 0 ? parseInt(num) : 1;
			if(this.cartStore.coverChargeNum > 30) {
				this.cartStore.coverChargeNum = 30;
			}
			sessionStorage.setItem('coverChargeNum', this.cartStore.coverChargeNum+"");
		},
		changeCustomerName(text) {
			this.cartStore.customerName = text.length > 0 ? text : null;
		},
		changeTableNumber(text) {
			this.cartStore.tableNumber = text.length > 0 ? text : null;
			sessionStorage.setItem('tableNumber', this.cartStore.tableNumber+"");
		},
		changePhone(text) {
			this.cartStore.customerPhone = text.length > 0 ? text : null;
		},
		changeAddress(address) {
			console.log("New address", address);
			this.cartStore.customerAddress = address;
			this.cartStore.customerAddressDetails = null;
			this.showAddressDetails = true;
			localStorage.setItem('customerAddress', JSON.stringify(address));
		},
		changeAddressDetails(details) {
			this.cartStore.customerAddressDetails = details;
			if(this.cartStore.customerAddress) {
				let address = this.cartStore.customerAddress;
				address.details = details;
				localStorage.setItem('customerAddress', JSON.stringify(address));
			}
		},
		getPrefillCustomerAddress() {
			let address = localStorage.getItem('customerAddress');
			if(address) {
				let addressData = JSON.parse(address);
				if(addressData.address) {
					this.cartStore.customerAddress = addressData;
					this.prefillAddress = addressData.address;
					if(addressData.details) {
						this.cartStore.customerAddressDetails = addressData.details;
						this.showAddressDetails = true;
					}
					return addressData.address;
				} else {
					localStorage.removeItem('customerAddress');
				}
			}
			return null;
		},
		changedCalendarDate() {
			if(!this.cartStore.notInPlaceOrderDate) {
				this.cartStore.notInPlaceOrderDate = new Date();
			}
			this.$refs['hour-picker'].checkSlotsAvailability();
		},
		focusPhoneIfAvailable() {
			if(this.menusStore.orderMode === 'qr' && this.menusStore?.room?.enabled_sms) {
				this.$refs["input-phone"].focus();
			}
		},
		focusNameTablePhoneIfAvailable() {
			if(this.menusStore.orderMode === 'qr') {
				if(['name', 'area'].includes(this.menusStore.qrType)) {
					this.$refs["input-name"].focus();
				} else if(['none'].includes(this.menusStore.qrType)) {
					this.$refs["input-table-number"].focus();
				} else if(this.menusStore?.room?.enabled_sms) {
					this.$refs["input-phone"].focus();
				}
			}
		},
	},
	mounted() {
		if(this.menusStore?.room?.enabled_sms) {
			this.cartStore.notificationType = 'sms';
		}
		this.cartStore.deliveryAsap = this.menusStore.isDeliveryNowAvailable;
        this.suggestedItems = this.cartStore.getSuggestedItems();

		if(this.timePickerShow) {
			if(this.$refs['hour-picker']) {
				this.$refs['hour-picker'].checkSlotsAvailability();
			}
		}

		this.getPrefillCustomerAddress();
	},
	watch: {
		"menusStore.orderMode"(newVal) {
			if(this.cartStore.notInPlaceOrderDate > this.maxDate) {
				this.cartStore.notInPlaceOrderDate = new Date(this.maxDate);
			}
			//TODO check if time is available in the other mode and set it null only if it is not instead of always
			this.cartStore.notInPlaceOrderTime = null;
            this.suggestedItems = this.cartStore.getSuggestedItems();
		},
		"cartStore.notInPlaceOrderDate"(newVal) {
			this.cartStore.notInPlaceOrderTime = null;
		},
		"menusStore.isDeliveryNowAvailable"(newVal) {
			if(!newVal && this.cartStore.deliveryAsap) {
				this.cartStore.deliveryAsap = false;
			}
		},
		"cartStore.sectionNameHighlight": function(newVal, oldVal) {
			if(newVal && newVal !== oldVal) {
				this.$refs["input-name"].focus();
			}
		},
		"cartStore.sectionTableNumberHighlight": function(newVal, oldVal) {
			if(newVal && newVal !== oldVal) {
				this.$refs["input-table-number"].focus();
			}
		},
		"cartStore.sectionPhoneHighlight": function(newVal, oldVal) {
			if(newVal && newVal !== oldVal) {
				this.$refs["input-phone"].focus();
			}
		},
		"cartStore.sectionNotInPlaceDataHighlight": function(newVal, oldVal) {
			if(newVal && newVal !== oldVal) {
				if(!this.cartStore.customerName) {
					this.$refs["input-order-data-name"].focus();
				} else if(!this.cartStore.customerPhone) {
					this.$refs["input-order-data-phone"].focus();
				} else if(!this.cartStore.customerAddress) {
					this.$refs["input-order-data-address"].focus();
				}
			}
		},
		"cartStore.cart": {
			handler: function(newVal, oldVal) {
				if(!this.crossSellClicking) {
                    this.suggestedItems = this.cartStore.getSuggestedItems();
				} else {
					this.crossSellClicking = false;
				}
			},
			deep: true
		},
		"timePickerShow": function(newVal) {
			if(newVal) {
				this.$nextTick(() => {
					if(this.$refs['hour-picker']) {
						this.$refs['hour-picker'].checkSlotsAvailability();
					}
				});
			}
		}
	}
}
</script>

<style lang="scss">
.vc-business {
	--vc-accent-50: var(--business-color-alpha20);
	--vc-accent-100: var(--business-color-alpha20);
	--vc-accent-200: var(--business-color-alpha40);
	--vc-accent-300: var(--business-color-alpha40);
	--vc-accent-400: var(--business-color-alpha60);
	--vc-accent-500: var(--business-color-alpha60);
	--vc-accent-600: var(--business-color-alpha80);
	--vc-accent-700: var(--business-color-alpha80);
	--vc-accent-800: var(--business-color);
	--vc-accent-900: var(--business-color);

	--vc-font-family: var(--font-family);
	--vc-rounded: var(--rounded-corner-s);
	--vc-rounded-md: var(--rounded-corner);
	--vc-rounded-lg: var(--rounded-corner);
	--vc-rounded-full: var(--rounded-corner-l);

	--vc-shadow: var(--theme-box-shadow);
	--vc-shadow-lg: var(--theme-box-shadow-strong);
	--vc-shadow-inner: inset var(--theme-box-shadow-light);

	&.vc-light {
		--vc-bg: var(--theme-bg1-color);
		--vc-color: var(--theme-txt2-color);
		--vc-border-color: var(--business-color);
		--vc-hover-bg: var(--theme-bg2-color);
		--vc-focus-ring: 0 0 0 2px var(--business-color-alpha60);

		--vc-header-arrow-color: var(--theme-txt2-color);
		--vc-header-arrow-hover-bg: var(--business-color-alpha20);
		--vc-header-title-color: var(--theme-txt1-color);
		--vc-weekday-color: var(--theme-txt2-color);
		--vc-weeknumber-color: var(--theme-txt2-color);

		--vc-nav-hover-bg: var(--business-color-alpha20);
		--vc-nav-title-color: var(--theme-txt1-color);
		--vc-nav-item-active-color: var(--business-text-color);
		--vc-nav-item-active-bg: var(--business-color-alpha80);
		--vc-nav-item-active-box-shadow: var(--theme-box-shadow);
		--vc-nav-item-current-color: var(--business-text-color);

		--vc-day-popover-container-color: var(--theme-bg1-color);
		--vc-day-popover-container-bg: var(--theme-bg2-color);
		--vc-day-popover-container-border: var(--theme-bg2-color);
		--vc-day-popover-header-color: var(--theme-txt1-color);

		--vc-popover-content-color: var(--theme-bg1-color);
		--vc-popover-content-bg: var(--theme-bg1-color);
		--vc-popover-content-border: var(--theme-bg2-color);

		--vc-select-color: var(--theme-txt1-color);
		--vc-select-bg: var(--theme-bg1-color);
		--vc-select-hover-bg: var(--theme-bg2-color);

		--vc-day-content-hover-bg: var(--theme-bg2-color);
		--vc-day-content-disabled-color: var(--theme-txt1-alpha20-color);

		&.vc-attr,
		& .vc-attr {
			--vc-highlight-outline-bg: var(--theme-bg2-color);
			--vc-highlight-solid-content-color: var(--business-text-color);
		}

		.vc-day-content:hover {
			color: var(--theme-txt1-color);
		}

		.vc-header {
			.vc-title {
				span {
					color: var(--theme-txt1-color);
				}
			}
		}

		.vc-nav-items {
			.vc-nav-item {
				color: var(--theme-txt2-color);

				&.is-active {
					color: var(--business-text-color);
				}
			}
		}
	}
}

</style>
<style scoped lang="scss">
.order {
	margin: var(--padding-vertical) var(--padding-horizontal);
	background-color: var(--theme-bg2-color);
	width: calc(100% - (var(--padding-horizontal) * 2));
	height: calc(100% - (var(--padding-vertical) * 2));
	border-radius: var(--rounded-corner);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	.order-body {
		width: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding: var(--padding-vertical) var(--padding-horizontal);
		overflow-y: auto;
		@include hideScrollbar();
		border-top-left-radius: var(--rounded-corner);
		border-top-right-radius: var(--rounded-corner);

		.order-section {
			width: 100%;
			margin-bottom: calc(var(--padding-vertical) * 2);
			position: relative;

			.section-title {
				margin-bottom: var(--padding-vertical);
				font-size: var(--font-size);
				line-height: var(--line-height);
				color: var(--theme-txt1-color);
				font-weight: 700;

				&.with-subtitle {
					margin-bottom: calc(var(--padding-vertical) / 2);
				}
			}

			.section-subtitle {
				margin-bottom: var(--padding-vertical);
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt2-color);
				font-weight: 400;
			}

			.section-content {
				padding: 0 var(--padding-horizontal);
				background: var(--theme-bg1-color);
				border-radius: var(--rounded-corner);
			}

			.section-content-input {

				&:deep(input), &:deep(textarea) {
					padding: var(--padding-vertical) var(--padding-horizontal-s);
					background: var(--theme-bg1-color);
					border-radius: var(--rounded-corner);
					width: 100%;
					min-height: 40px;
				}

				&.section-phone-input {
					gap: 4px;
					display: flex;
					justify-content: flex-start;
					align-items: stretch;

					.menu-text-input {
						flex-grow: 1;
					}
				}
			}

			.section-buffer-buttons {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 8px;

				.menu-button {
					width: 50%;
					padding: 6px var(--padding-horizontal);
					border: 2px solid var(--business-color);
					transition: box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out, border 0.2s ease-in-out, background-color 0.2s ease-in-out;

					&:deep(.menu-button-text) {
						font-size: var(--font-size);
						line-height: var(--line-height);
					}

				}

			}
			.buffer-explain {
				font-size: var(--font-size-s);
				line-height: var(--line-height-s);
				color: var(--theme-txt2-color);
				font-weight: 400;
				margin-top: 12px;
			}

			.section-content-data-input {
				gap: 8px;
				display: flex;
				flex-direction: column;
				padding: var(--padding-vertical) var(--padding-horizontal);

				&:deep(input), &:deep(textarea) {
					width: 100%;
					background: var(--theme-bg2-color);
					min-height: 40px;
				}

				&:deep(.phone-prefix-input) {
					background: var(--theme-bg2-color);
				}

				.section-phone-input {
					gap: 8px;
				}

			}

			.cross-selling-category-container {
				padding: 0;
				box-shadow: inset var(--theme-box-shadow-xlight);

				.cross-selling-category {
					display: flex;
					gap: var(--padding-vertical);
					padding: var(--padding-horizontal-s);
					flex-wrap: nowrap;
					overflow-x: auto;
					overscroll-behavior: unset;
					touch-action: unset;
					text-align: left;
					scrollbar-width: thin;
					scrollbar-color: var(--theme-bg2-color) var(--theme-bg1-color);
					align-items: stretch;

				}
			}


			&.order-section-time-picker {

				.switch {
					box-shadow: var(--theme-box-shadow-light);
					position: relative;
					z-index: 5;
					border-radius: var(--rounded-corner);
					transition: box-shadow 0.3s ease-in-out;
				}

				.time-picker-container {
					width: 100%;
					background-color: var(--theme-bg1-color);
					transform: translateY(-16px);
					margin-bottom: -16px;
					padding-top: 18px;
					border-bottom-left-radius: var(--rounded-corner);
					border-bottom-right-radius: var(--rounded-corner);
					box-shadow: inset var(--theme-box-shadow-xlight);
					transition: transform 0.3s linear;
					max-height: 60px;
					cursor: pointer;
					position: relative;
					z-index: 3;

					&.noHeader {
						padding-top: 0;
						margin-bottom: 0;
						border-radius: var(--rounded-corner);
						transform: translateY(0);
						transition: none;
					}

					.calendar-container {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						width: 100%;
						padding: 4px var(--padding-horizontal);
						max-height: 50px;
						overflow: hidden;
						transition: padding 0.3s ease-in-out, max-height 0.3s ease-in-out;

						.calendar-icon {
							width: 22px;
							height: 22px;
							display: flex;
							justify-content: center;
							align-items: center;
							padding: 3px;

							&:deep(svg) {
								width: 100%;
								height: 100%;

								.fill {
									fill: var(--business-color);
								}

								.stroke {
									stroke: var(--business-color);
								}

							}
						}

						.calendar-text {
							font-size: var(--font-size);
							line-height: var(--line-height);
							color: var(--theme-txt1-color);
							margin-left: 8px;
						}
					}
				}

				.hour-picker-container {
					width: 100%;
					padding: var(--padding-vertical) var(--padding-horizontal);
					background-color: var(--theme-bg1-color);
					border-radius: var(--rounded-corner);
					box-shadow: inset var(--theme-box-shadow-xlight);
					margin-top: calc(var(--padding-vertical) / 2);
					transition: padding 0.3s ease-in-out, margin-top 0.3s ease-in-out, max-height 0.3s ease-in-out;
					overflow: hidden;
					max-height: 500px;
				}

				&.hideCalendar {
					.time-picker-container {
						transform: translateY(-18px);

						.calendar-container {
							padding: 0 var(--padding-horizontal);
							max-height: 0;
						}
					}

					.switch {
						box-shadow: var(--theme-box-shadow-null);
					}

					.hour-picker-container {
						padding: 0 var(--padding-horizontal);
						margin-top: 0;
						max-height: 0;
					}
				}
			}


			&:after {
				content: "";
				display: flex;
				position: absolute;
				top: calc(var(--padding-vertical) * -1);
				left: calc(var(--padding-horizontal) * -1);
				width: calc(100% + (var(--padding-horizontal) * 2));
				height: calc(100% + (var(--padding-vertical) * 2));
				border-radius: var(--rounded-corner);
				background: var(--business-color);
				opacity: 0;
				transition: opacity 0.5s ease-in-out;
				pointer-events: none;
			}

			&.highlight {

				&:after {
					opacity: 0.25;
				}

			}
		}

	}

	&.mobile {
		margin: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;

		.order-body {
			padding-top: calc(var(--padding-vertical) * 2);
			padding-bottom: 180px;
		}
	}

}
</style>